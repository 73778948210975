footer{
    min-height: 200px;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #FF5693;
    color: white;
    font-weight: 400;
}

#insta, #linkedin{
  height: 50px;
  filter: invert(1);
  margin: 10px
}

footer > * {
  width: 33%;
}

@media only screen and (max-width: 600px) {
  footer{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  footer * {
    text-align: center;
    width: 100%;
  }

  #insta, #linkedin{
    width: 50px;
  }
}
#hero{
    position: relative;
    padding: 100px 40px 40px 40px;
    height: calc(100vh);
    min-height: 600px;
}

#hero-container{
  background-image: url('../Assets/hero.webp');
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  padding: 2rem;

}

#hero-info{
  height: 100%;
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#hero-info h1{
  width: 950px;
  max-width: 100%;
}

#hero-info p{
  width: 780px;
  max-width: 100%;
  font-size: 20px;
  margin: 30px 0;
  font-weight: 300;
}

@media only screen and (max-width: 750px) {
  #hero-container{
  
    padding: 1rem;
  
  }

  #hero{
    position: relative;
    padding: 60px 20px 20px 20px;
    height: calc(100vh);
    min-height: 600px;
}
}



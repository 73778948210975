#scroller {
    background-color: #CC3D72;
    display: flex;
    overflow: hidden; /* Hide overflow to create a continuous effect */
    height: 50px;
    white-space: nowrap; /* Keep all items on a single line */
    align-items: center;
    position: relative;
}

.scroller-content {
    display: flex;
    animation: scroll 200s linear infinite; /* Apply scroll animation */
}

.scroller-item {
    padding: 0 25px;
    border-right: 1px solid white;
    color: white;
    font-family: "Taviraj", serif;
    text-transform: uppercase;
    font-style: italic;
    font-size: 28px;
}

@keyframes scroll {
    from {
        transform: translateX(0); /* Start at the original position */
    }
    to {
        transform: translateX(-100%); /* Move entire width to create loop effect */
    }
}

header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 100%;
    margin: 0 auto;
    z-index: 999;
    padding: 0 40px;
    position: fixed;
    background-color: #fafafa;

}

nav a{
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  margin-left: 20px;
  color: #313131;
}

#desktop-nav{
    display: flex;
}

#mobile-logo, #mobile-nav{
    display: none;
}

.nav-open{
    width: 100vw;
}

.nav-closed{
    width: 0px;
}

.nav-closed * {
    opacity: 0;
}

.mobile-link{
    transition: 1s;
    color: white;
}

#burger{
    display: none;
}

#nav-logo{
    max-width: 50vw;
}

.mobile-a{
    color: white;
    margin: 2rem 0;
    font-size: 18px;
}



@media only screen and (max-width: 750px) {
    #desktop-nav{
        display: none;
    }

    #mobile-logo{
        display: block;
    }

    .mobile-link{
        margin: 20px;
        font-size: 20px;
    }

    #mobile-nav{
        display: flex;
        flex-direction: column;
        justify-content: baseline;
        align-items: center;
        background-color: #CC3D72;
        color: white;
        height: 100vh;
        position: fixed;
        right: 0;
        top: 0;
        transition: 1s;
        overflow: hidden;
        z-index: 998;
        padding-top: 100px;
    }

    .scrolling, .not-scrolling{
        justify-content: space-between;
        padding: 0 20px;
    }

    #burger{
        height: 40px;
        width: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        z-index: 999;
        overflow: visible;
    }
    
    .burger-closed{
        width: 100%;
        height: 2px;
        background-color: #313131;
        transition: 1s ;
    }
    
    .burger-top-open{
        width: 40px;
        height: 2px;
        background-color: #fff;
        transform: rotate(45deg);
        transform-origin: top left;
        transition: 1s ;
        z-index: 1000;
        overflow: visible;
    }
    
    .burger-bottom-open{
        width: 40px;
        height: 2px;
        background-color: #fff;
        transform: rotate(-45deg);
        transform-origin: 10px 5px;;
        transition: 1s ;
    
    }
    header{
        width: 100vw;
        height: 60px;
        padding: 0 20px;
    }
}

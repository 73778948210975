#overview{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: #fafafa;
    min-height: 550px;
    width: 1200px;
    margin: 0 auto;
    max-width: 95vw;
    justify-content: center;

}

#overview-header, #overview-description{
    padding: 25px 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

#overview-description{
    font-weight: 400;
    font-family: 'Lato';
}

#overview-header h2, #overview-header span{
    font-family: 'Poppins';
    font-size: 42px;
    width: 70%;
}

#overview-header span{
    color: #d7d2cb;
}

#overview-description p{
    width: 70%;
    margin: 1rem 0;
    font-size: 18px;
    line-height: 28px;

}

#download-btn{
    border: 2px solid #d7d2cb;
    padding: 10px;
    color: #d7d2cb;
    background-color: unset;
    margin: 2rem 0;
    font-weight: 600;
    font-family: 'Lato';
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 5px;
}

@media only screen and (max-width: 1000px) {
    #overview{
        flex-direction: column;
        padding: 2rem 0;
    }
    #overview-header, #overview-description{
        width: 100%;
        padding: 1rem 0;

    }

    #overview-header h2, #overview-header span, #overview-description p{
        width: 90%;

    }
  }
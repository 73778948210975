#what-we-do{
    background-color: #fafafa;
    width: 1400px;
    max-width: 100vw;
    margin: 1rem auto;
    text-align: center;
    overflow: visible;
}

.what-we-do-container{
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    min-height: 700px;
}

.what-info{
    width: 600px;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
    text-align: left;
}

.what-info p{
    margin: 1rem 0;
    font-size: 18px;
  line-height: 28px;
}

#tab-titles{
    display: flex;
    justify-content: center;
    width: 1200px;
    max-width: 100vw;
    padding: 1rem 0;
    margin: 0 auto;
}

.tab-title{
    padding: 1rem 2rem;
    font-weight: 400;
    font-family: 'Lato';
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 5px;

}

.tab-title:hover{
    cursor: pointer;
}

.active{
    border-bottom: 2px solid #d7d2cb;
}

.slider-img{
    height: 600px;
    width: 600px;
    max-width: 100vw;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
}

.what-we-do-slider-container{
    display: flex;
    margin: 1rem;
    position: relative;
    transition: 1s;
    width: 100%;
    justify-content: space-evenly;

}

.what-we-do-sliders{
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 1rem 0;
    overflow: scroll;
    width: 100%;
}


@media only screen and (max-width: 750px) {
    .img-container, .what-img, .what-info, .overlay-white,.overlay-white-right {
        width: 100vw;
    }
    .overlay-white, .overlay-white-right{
        background: linear-gradient(to bottom, rgba(250,250,250, 0) 30%, rgba(250,250,250, 0.3) 90%, rgba(250,250,250, 1) 100% );

    }
    .desktop{
        display: none;
    }
    
    .mobile{
        display: block;
    
    }
    .what-info{
        padding: 2rem 1rem;
    }
    .tab-title{
        width: 30vw;
        padding: 10px;
        font-size: 10px;
        display: flex;
        justify-content: center;
        
    }

    #tab-titles{
        display: flex;
        justify-content: space-between;
        overflow: scroll;
    }

    .what-we-do-slider-container{
        margin: 0;
        flex-direction: column;
        justify-content: unset;
    }

    .slider-img{
        width: 100vw;
    }
}
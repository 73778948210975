*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: lighter;
  color: #313131;
}

a{
  text-decoration: none;
}
p{
  font-size: 18px;
  line-height: 28px;
}

button:hover, a:hover{
  cursor: pointer;
}

html{
  background-color: #fafafa;
}


h1, h2, h3{
  font-family: "Taviraj", serif;
}

h1{
  color: #CC3D72;
  font-weight: 300;
  font-style: italic;
  font-size: 72px;
  text-transform: uppercase;
  line-height: 64px;
}

h2{
  font-weight: 300;
  font-style: italic;
  font-size: 48px;
  text-transform: uppercase;
  line-height: 37px;
  position: relative;
  top: -15px;
  left: 10px;
  white-space: nowrap;
}

.white-btn{
  background-color: unset;
  border: 1px solid white;
  color: white;
  padding: 10px 20px;
  text-transform: uppercase;
  width: 150px;
  font-size: 16px;
}

.black-btn{
  background-color: unset;
  border: 1px solid #313131;
  color: #313131;
  padding: 10px 20px;
  text-transform: uppercase;
  width: 150px;
  font-size: 16px;
}

.pink-btn{
  background-color: unset;
  border: 1px solid #FF5693;
  color: #FF5693;
  padding: 10px 20px;
  text-transform: uppercase;
  width: 150px;
  font-size: 16px;
}


.beige{
  background-color: #fbf9f5;
  padding: 4rem 2rem;
}

.pink{
  background-color: #CC3D72;
  padding: 4rem 2rem;
  overflow: hidden;
}

.yellow-header {
  align-self: flex-start; /* Prevents stretching across the container */
  background-color: #FFCD78;
  display: inline-flex; /* Allows it to only take the width of its content */
  margin: 30px 0;
  align-items: center; /* Vertically centers the text inside the header */
}

.beige-header{
  background-color: #F4F0E6;
  display: inline-flex; /* Allows it to only take the width of its content */
  margin: 30px 0;
  align-items: center; /* Vertically centers the text inside the header */
  align-self: flex-start; /* Prevents stretching across the container */

}

.pink-header {
  background-color: #FF5693;
  display: inline-flex; /* Only take width of content */
  padding: 0;
  align-items: center;
  margin: 30px auto;
  align-self: flex-start; /* Prevents stretching */

}

.info-container{
  width: 1300px;
  max-width: 100%;
  margin: 0 auto;
}

.info-img {
  height: 600px;
  width: 600px;
  object-fit: cover;
  object-position: center;
  flex-shrink: 0; /* Keeps the image at its set size */
}

.info-pic-container {
  display: flex;
  justify-content: space-between;
  padding: 4rem 0 4rem 4rem;
  width: 100%; /* Ensures the container takes up full width */
  max-width: 1600px;
  margin: 0 auto;
}

#experience-scroller{
  margin: 50px 0;
  overflow-x: auto; /* Enables horizontal scrolling only */
  overflow-y: hidden; /* Hides any vertical scroll */
  display: flex;
  width: 100vw; /* Set a width that suits your design, 80vw or a specific px value */
  scroll-behavior: smooth; /* Smooth scrolling */
}

.benefit-number{
  color: #FFCD78;
  font-weight: 300;
  font-style: italic;
  margin-right: 10px;
  font-size: 42px;
  line-height: 37px;
}

#process-wrapper {
  overflow: hidden; /* Keeps only one step visible */
  width: 100%; /* Ensures the wrapper doesn’t cut off any slides */
  margin: 50px 0;
}

#process-slider {
  display: flex;
  transition: transform 0.5s ease; /* Smooth sliding */
}

.process-step {
  display: flex;
  margin-right: 30px;
  width: 450px; /* Fixed width per step */
  flex-shrink: 0;
}


.horizontal-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allows this container to expand to available space */
  padding: 0 2rem;
}

.hover:hover{
  cursor: pointer;
}

#insta-feed {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  grid-template-rows: repeat(2, 1fr); /* 2 rows */
  gap: 10px;
  margin: 30px 0
}

.grid-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.team-info{
  width: 50%;
  padding-right: 30px;
}

.top-margin{
  margin-top: 50px;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 14vw;
    line-height: 12vw;
  }
  h2 {
    font-size: 8vw;
    line-height: 7vw;
    white-space: wrap;
    top: -3vw;
  }

  .beige{
    padding: 0;
  }

  .pink{
    padding: 2rem 1rem;
  }

  .info-pic-container {
    flex-direction: column;
    padding: 0;
  }

  .info-img {
    height: calc(100vw - 2rem);
    width: 100%;
    object-fit: cover;
    object-position: center;
    flex-shrink: 0; /* Keeps the image at its set size */
  }

  .info-container, .horizontal-info {
    padding: 2rem 1rem ;
  }

  .mobile-wrap{
    flex-direction: column;
  }

  .team-info{
    width: 100%;
    padding-right: 0;
    padding: 2rem;
  }

  .yellow-header, .pink-header, .beige-header{
    height: 7vw;
  }

  .mobile-remove{
    display: none;
  }

  #process{
    padding: 0;
  }
  
.top-margin{
  margin-top: 30px;
}

}

@media (max-width: 450px) {
  .process-step {
    width: calc(100vw - 2rem); /* Adjust width for small screens, leaving padding */
    margin-right: 0; /* Reduce margin for better spacing */
  }
}

@media (max-width: 350px) {
  .process-step {
    width: calc(100vw - 2rem); /* Slightly smaller width for very narrow screens */
    margin-right: 0; /* Minimal margin for tight spaces */
  }
}
.accordion {
  flex-grow: 1; /* Ensures the accordion fills remaining space */
  overflow: hidden;
}
  
  .accordion-header {
    padding: 20px 0;
    border-bottom: 1px solid black;
    color: #313131;
    font-family: 'Kamerik', sans-serif;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-header:hover {
    cursor: pointer;
  }
  
  .arrow-open {
    height: 15px;
    transition: 0.3s ;
  }
  
  .arrow-closed {
    height: 15px;
    transform: rotate(-90deg);
    transition: 0.3s ;
  }
  
  /* Accordion Body */
  .accordion-body {
    overflow: hidden;
    max-height: 0;
    transition: 0.5s;
  }
  
  .accordion-body.open {
    max-height: 2000px;
    padding: 10px 0;
    opacity: 1;
  }
  
  .accordion-body.collapsed {
    max-height: 0;
    padding: 1px;
    opacity: 0;
  }
  
  @media only screen and (max-width: 750px) {
    #services {
      justify-content: space-between;
      flex-wrap: wrap;

    }

    .accordion {
      width: 100%; 
    }
  }
  
form{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 500px;
  justify-content: center;
  align-items: center;
}

input{
  border: unset;
  height: 48px;
  margin-bottom: 20px;
  padding-left: 10px;
  width: 100%;
}

textarea{
  height: 120px;
  border: unset;
  resize: none;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
}

#contact{
  padding: 2rem;
}
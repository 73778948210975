#landing-image{
    height: 100vh;
    width: 50vw;
    object-fit: cover;
    object-position: center;
}

#landing-logo{
    width: 220px;

}

#landing-left{
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
}

#landing-page{
    display: flex;
    flex-wrap: wrap;
}

#landing-txt{
    font-weight: 400;
  font-family: 'Lato';
  color: #313131;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-top: 1rem;
  margin-bottom: 50px;
  width: 500px;
  line-height: 25px;
}

.landing-social{
    filter: invert(1);
}

.main-txt{
    color:'#313131';
    font-family: 'Poppins';
    margin:'1rem';
}

#social-container{
    margin-top: 50px;
}

@media only screen and (max-width: 980px) {
    #landing-image{
        display: none;
    }

    #landing-left{
        width: 100vw;
        height: 100vh;
        background-image: url('../Assets/content.jpg');
        background-position: center;
        background-size: cover;
        color: white;
        justify-content: left;
        padding: 4rem 1rem;
    }

    .landing-social{
        filter: invert(0);
    }

    #landing-txt{
        color: white;
        max-width: 90vw;
    }
    #landing-logo{
        filter: invert(1);
        max-width: 90vw;
    
    }

    #social-container{
        margin-top: 150px;
    }
  }
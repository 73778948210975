#advidity-btn{
    border: 2px solid #EB9486;
    color: #EB9486;
    background-color: unset;
    padding: 10px;
    font-weight: bold;
    transition: 0.5s ;

}

#advidity-btn:hover{
    background-color: #EB9486;
    color: white ;
    transition: 0.5s ;
}

#academy-info{
    width: 800px;
    max-width: 100vw;
    padding: 1rem;
    margin: 1rem auto;
}

#academy-info p{
    margin: 1rem 0;
    font-size: 18px;
    line-height: 28px;
}
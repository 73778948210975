#about{
    min-height: 600px;
    background-color: #fafafa;
    color: white;
    display: flex;
    flex-wrap: wrap;

}

.video-background{
    position: absolute;
    width: 50vw;
    height: 600px;
    top: 0;
    right: 0;
    object-fit: cover;
    object-position: center;

}

.typer{
    font-family: 'Poppins';
    color: #1e1e1e;
    font-weight: bold;
}

.benefits{
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 5px;
    text-align: left;
    margin: 40px 0;
    line-height: 24px;
    list-style-type: none;
}

.left-curved-container{
    width: 50vw;
    height: 600px;
    border-top-right-radius: 1000px;
    border-bottom-right-radius: 1000px;
    /*box-shadow: 45px 0 80px -6px rgba(0,0,0,.21);*/
    background-size: cover;
    background-position: center;
    margin: 75px 0;

}

.right-curved-container{
  width: 50vw;
  height: 600px;
  border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  background-size: cover;
  background-position: center;
  margin: 75px 0;
  position: relative;
  overflow: hidden;

}

.right-txt-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50vw;
  padding: 3rem;

}

.right-txt-container p{
  font-size: 18px;
  line-height: 28px;
  margin: 1rem;
}

@media only screen and (max-width: 1000px) {
    .right-txt-container{
        width: 100vw;
        padding: 1rem;
    }
    .left-curved-container{
        width: 90vw;
    }

    .right-curved-container{
        width: 90vw;
        margin-left: 10vw;

    }

    .video-background{
        width: 90vw;

    }
}